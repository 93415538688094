const types = [
    {
        title: "Avidbots Scrubbers",
        description:
            "Avidbots fully autonomous floor scrubbers will revolutionize your facility's cleaning program! Lower costs and improve your floor cleaning efficiency with the Neo 2™ robotic floor scrubber, for numerous commercial and industrial applications.",
        path: "/bestfloorcleaningmachines/assets/images/avidbots_scrubbersOpt.png",
    },
    {
        title: "Tennant Scrubbers",
        description:
            "These industrial floor scrubbers are thoughtfully designed to tackle the toughest surfaces and also handle the most delicate floors. Both dependable and thorough, these scrubbers will keep your floors looking their best for years to come.",
        path: "/bestfloorcleaningmachines/assets/images/tennant_scrubbersOpt.png",
    },
    {
        title: "Bulldog Scrubbers",
        description:
            "These cordless scrubbers allow for heavy-duty cleaning in smaller applications. Compact and industrial grade, these machines are able to withstand daily use in tough environments, including gym mats, brewery floors, and more.",
        path: "/bestfloorcleaningmachines/assets/images/bulldog_scrubbersOpt.png",
    },
    {
        title: "PowerBoss Scrubbers",
        description:
            "These American-made scrubbers combine innovative design and cost-effective reliable operation into one floor scrubbing machine. An ideal solution for heavy-duty cleaning, these scrubbers make cleaning quicker and more effective.",
        path: "/bestfloorcleaningmachines/assets/images/powerboss_scrubbersOpt.png",
    },
    {
        title: "Factory Cat Scrubbers",
        description:
            "These scrubbers, made in America, incorporate heavy duty steel construction to achieve outstanding quality and durability in one machine. Effective across a variety of applications, these machines are ready to efficiently clean your floors.",
        path: "/bestfloorcleaningmachines/assets/images/factory_cat_scrubbersOpt.png",
    },
    {
        title: "Simple Clean Scrubber",
        description:
            "These compact commercial floor scrubbers emphasize simplicity and affordability. Best for smaller applications, these floor scrubbers are ideal for the small business owner. They will give your facility a thorough clean on a budget.",
        path: "/bestfloorcleaningmachines/assets/images/simple_clean_scrubberOpt.png",
    },
];

export default types;
