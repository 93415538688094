import { ReactElement } from "react";
import types from "./floorCleaningMachineTypes";
import FeatureList from "@components/shared/featureList";
export default function FAQ(): ReactElement {
    return (
        <FeatureList
            featureList={types}
            mainTitle="Commercial & Industrial Floor Scrubbers"
            classes={{
                mainTitleClasses:
                    "font-bold capitalize mb-8 md:w-9/12 mx-auto md:mb-10 text-2xl text-center  md:text-3xl",
                titleClasses: "text-xl text-center font-bold mb-8",
                descriptionClasses: "text-base text-center font-light",
                innerSectionClasses:
                    " grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 md:gap-40 ",
                oneBoxClasses: " flex flex-col items-center",
                mainSectionClasses: "flex flex-col  my-10 md:my-24",
                iconClasses: "relative mb-14 w-[190px] h-[190px]",
            }}
            colors={{
                descriptionColor: "#676767",
            }}
        />
    );
}
